<template>
	<div>
		<v-btn-toggle dense class="d-flex">
			<v-btn
				:color="attendButtonColor"
				:loading="loading || loadingParent"
				:outlined="event.attending || loading || loadingParent"
				:disabled="event.attending || !authenticated || event.status.closed.value"
				@click="openDialogAttend()"
				class="flex-grow-1"
			>
				<span>{{ attendButtonText }}</span>
			</v-btn>
			<v-menu offset-y close-on-click close-on-content-click v-model="buttonsMenu">
				<template v-slot:activator="{ on: onMenu, attrs }">
					<v-btn color="primary" icon v-bind="attrs" v-on="onMenu" v-show="event.attending">
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-dialog v-model="dialogMiss" persistent max-width="350">
						<template v-slot:activator="{ on: onDialogMiss }">
							<v-list-item v-on.stop="onDialogMiss">
								<v-list-item-title>{{ $t('events.leaveEvent') }}</v-list-item-title>
							</v-list-item>
						</template>
						<v-card rounded="xl">
							<v-card-title>
								<span class="headline">{{ $t('courses.areYouSure') }}</span>
							</v-card-title>
							<v-card-actions>
								<v-spacer />
								<v-btn color="error darken-1" text @click="dialogMiss = false">
									{{ $t('offers.cancel') }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="callMissEvent()">
									{{ $t('search.continue') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-list>
			</v-menu>
		</v-btn-toggle>
		<i18n path="events.leftCapacity" tag="span" class="text-center text-body-1">
			<template #number>
				<span style="color: #ff6f00">{{ leftCapacityNumber }}</span>
			</template>
		</i18n>
		<v-dialog v-model="dialogAttend" persistent max-width="350">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('courses.areYouSure') }}</span>
				</v-card-title>
				<v-card-text class="text-justify">
					<v-form ref="form" v-model="valid">
						<template v-if="hasTemplates">
							<p class="text-body-1">
								{{ $t('documents.linkDocumentsHelp') }}
							</p>
							<v-autocomplete
								v-model="template"
								:label="$t('documents.template')"
								:placeholder="$t('studies.select')"
								:items="templates"
								item-text="name"
								item-value="id"
								return-object
								outlined
								clearable
							/>
						</template>
						<template v-else>
							<p class="text-body-1" style="color: red">
								{{ $t('documents.noTemplateToLink1') }}
							</p>
							<p class="text-body-1">
								{{ $t('documents.noTemplateToLink2') }}
							</p>
							<v-btn color="warning" :to="{ name: 'SettingsDocuments' }">
								{{ $t('documents.goToDocuments') }}
							</v-btn>
						</template>
						<template v-if="event ? event.type.code == 'interview' : false">
							<v-select
								v-model="time"
								:label="$t('events.chooseATime')"
								:placeholder="$t('studies.select')"
								:items="availableTimes"
								item-text="time"
								item-value="time"
								:rules="rules.time"
								return-object
								outlined
							/>
						</template>
						<template v-else-if="event ? event.type.code == 'workshop' : false">
							<p class="text-body-1">
								{{ $t('events.workshopAttendanceAlert') }}
							</p>
						</template>
					</v-form>
					<br />
					<br />
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="error darken-1" text @click="closeDialogAttend()">
						{{ $t('offers.cancel') }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="!!template ? callAttendEvent() : openDialogAttendSecond()">
						{{ $t('search.continue') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogAttendSecond" persistent max-width="350">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('courses.areYouSure') }}</span>
				</v-card-title>
				<v-card-text class="text-justify">
					<span>{{ $t('offers.noTemplateSelected') }}</span>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="error darken-1" text @click="closeDialogAttendSecond()">
						{{ $t('offers.cancel') }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="callAttendEvent()">
						{{ $t('search.continue') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EventAttendButton',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dialogAttend: false,
			dialogAttendSecond: false,
			dialogMiss: false,
			buttonsMenu: false,
			loading: false,
			attendButtonText: '',
			actionStarted: false,
			actionFinished: false,
			actionSuccess: false,
			valid: false,
			template: null,
			time: null,
			rules: {
				template: [
					(v) => {
						return this.hasTemplates && v ? (v && this.templates.map(({ id }) => id).includes(v.id)) || this.$t('rules.notSupported') : true
					},
				],
				time: [
					(v) => {
						return this.event.type.code == 'interview' ? !!v || this.$t('rules.required') : true
					},
					(v) => {
						return this.event.type.code == 'interview' ? this.event.spots.includes(v) || this.$t('rules.notSupported') : true
					},
				],
			},
		}
	},
	watch: {
		event() {
			if (this.event.attending) {
				this.attendButtonText = this.$t('events.alreadyAttending')
			} else {
				this.attendButtonText = this.$t('events.attendButton')
			}
			this.$refs.form.resetValidation()
		},
		authenticated() {
			if (this.authenticated) this.fetchDocumentsTemplates()
		},
	},
	computed: {
		attendButtonColor() {
			if (this.actionFinished && this.actionSuccess) {
				return 'success'
			} else if (this.actionFinished && !this.actionSuccess) {
				return 'warning'
			} else {
				return 'primary'
			}
		},
		leftCapacityNumber() {
			if (this.event.type.code == 'interview') {
				let remaining = this.event.attendances.spotsLeft || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			} else {
				let remaining = this.event.attendances.maxCapacity - this.event.attendances.numTotal || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			}
		},
		hasTemplates() {
			return this.templates ? !!this.templates.length : false
		},
		availableTimes() {
			return this.event ? Array.prototype.slice.call(this.event.spots).sort((a, b) => a.time > b.time) : []
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			event: 'events/event',
			attendance: 'attendances/attendance',
			templates: 'documents/templates',
		}),
	},
	created() {
		if (this.event.attending) {
			this.attendButtonText = this.$t('events.alreadyAttending')
		} else {
			this.attendButtonText = this.$t('events.attendButton')
		}
		if (this.authenticated)
			this.fetchDocumentsTemplates().then(() => {
				this.$refs.form.resetValidation()
			})
	},
	methods: {
		openDialogAttend() {
			this.dialogAttendSecond = false
			this.dialogAttend = true
		},
		closeDialogAttend() {
			this.dialogAttend = false
		},
		openDialogAttendSecond() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.dialogAttend = false
			this.dialogAttendSecond = true
		},
		closeDialogAttendSecond() {
			this.dialogAttendSecond = false
			this.dialogAttend = true
		},
		callAttendEvent() {
			if (!this.event.attending) {
				this.$refs.form.validate()
				if (!this.valid) return
				this.actionStarted = true
				this.attendEvent({
					event: this.event,
					templateID: this.template ? this.template.id : 0,
					time: this.event.type.code == 'interview' ? this.time : null,
				})
					.then((data) => {
						this.actionSuccess = data.success
					})
					.catch(() => {
						this.actionSuccess = false
					})
					.then(() => {
						this.actionFinished = true
					})
			}
			this.dialogAttend = false
			this.dialogAttendSecond = false
		},
		callMissEvent() {
			if (this.event.attending) {
				this.missEvent(this.attendance)
			}
			this.dialogMiss = false
		},
		...mapActions('events', ['attendEvent', 'missEvent']),
		...mapActions('documents', ['fetchDocumentsTemplates']),
	},
}
</script>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
